// @flow
'use strict';

import * as React from 'react';

import type { IDadosVeiculo } from '../../../types';

import RowDetalhes from '../../../../../common/detalhes_documento/row_detalhes';
import ColumnDetalhes from '../../../../../common/detalhes_documento/column_detalhes';
import DadosProprietario from './dados_proprietario';

type DadosVeiculoProps = {
  dadosVeiculo : IDadosVeiculo,
};

export default function DadosVeiculo({ dadosVeiculo } : DadosVeiculoProps) {
  if (!dadosVeiculo)
    return null;

  return (
    <>
      <h3>Dados do Veículo</h3>
      <RowDetalhes data={ dadosVeiculo }>
        <ColumnDetalhes field="placa">Placa</ColumnDetalhes>
        <ColumnDetalhes field="renavam">RENAVAM</ColumnDetalhes>
        <ColumnDetalhes field="ufLicenciamento">UF Licenciamento</ColumnDetalhes>
      </RowDetalhes>

      { dadosVeiculo.dadosProprietario && <DadosProprietario dadosProprietario={ dadosVeiculo.dadosProprietario }/> }
    </>
  );
}
