// @flow
'use strict';

import * as React from 'react';

import type { ITransporte } from '../types';
import { tipoDocumento } from './abas_informacoes';

import RowDetalhes from '../../../common/detalhes_documento/row_detalhes';
import ColumnDetalhes from '../../../common/detalhes_documento/column_detalhes';
import TableDetalhes from '../../../common/detalhes_documento/table_detalhes';
import TableDetalhesComLinhas from '../../../common/detalhes_documento/table_detalhes_com_linhas';

type TransporteProps = {
  transporte : ITransporte,
};

export default function Transporte({ transporte } : TransporteProps) {
  const { transportador, retencaoIcms, veiculo, reboque, volumes, lacres } = transporte || {};

  return (
      <div className="tab-pane active">
        <h3>Transporte</h3>
        <RowDetalhes data={ transporte }>
          <ColumnDetalhes field="modalidade">Modalidade do Frete</ColumnDetalhes>
        </RowDetalhes>

        { transportador &&
        <>
          <h3>Transportador</h3>
          <TableDetalhesComLinhas data={ transportador }>
            <tr>
              <ColumnDetalhes field="cnpj" type="documento"
                              width="33%">{ tipoDocumento(transportador) }</ColumnDetalhes>
              <ColumnDetalhes field="nome">Nome / Razão Social</ColumnDetalhes>
            </tr>
            <tr>
              <ColumnDetalhes field="inscricaoEstadual">Inscrição Estadual</ColumnDetalhes>
              <ColumnDetalhes field="endereco">Endereço</ColumnDetalhes>
            </tr>
            <tr>
              <ColumnDetalhes field="municipio">Município</ColumnDetalhes>
              <ColumnDetalhes field="uf">UF</ColumnDetalhes>
            </tr>
          </TableDetalhesComLinhas>
        </>
        }

        { retencaoIcms &&
        <>
          <h3>Retenção de ICMS</h3>
          <RowDetalhes data={ retencaoIcms } className="no-margin-bottom">
            <ColumnDetalhes field="valorServico" type="moeda" width="33%">Valor do Serviço</ColumnDetalhes>
            <ColumnDetalhes field="bcRetencaoIcms" type="moeda" width="33%">BC Retenção do ICMS</ColumnDetalhes>
            <ColumnDetalhes field="aliquota" type="percentual" width="33%">Alíquota</ColumnDetalhes>
          </RowDetalhes>
          <RowDetalhes data={ retencaoIcms }>
            <ColumnDetalhes field="valorIcmsRetido" type="moeda" width="33%">Valor ICMS Retido</ColumnDetalhes>
            <ColumnDetalhes field="cfop" width="33%">CFOP</ColumnDetalhes>
            <ColumnDetalhes field="codMunOcorrencia" width="33%">Cód. do Mun. Ocorrência</ColumnDetalhes>
          </RowDetalhes>
        </>
        }

        { veiculo &&
        <>
          <h3>Veículo</h3>
          <RowDetalhes data={ veiculo }>
            <ColumnDetalhes field="placa" width="33%">Placa do Veículo</ColumnDetalhes>
            <ColumnDetalhes field="uf" width="33%">UF</ColumnDetalhes>
            <ColumnDetalhes field="rntc" width="33%">RNTC</ColumnDetalhes>
          </RowDetalhes>
        </>
        }

        { reboque &&
        <>
          <h3>Reboques</h3>
          <RowDetalhes data={ reboque }>
            <ColumnDetalhes field="placa" width="33%">Placa</ColumnDetalhes>
            <ColumnDetalhes field="uf" width="33%">UF</ColumnDetalhes>
            <ColumnDetalhes field="rntc" width="33%">RNTC</ColumnDetalhes>
          </RowDetalhes>
        </>
        }

        <h3>Volumes</h3>
        <TableDetalhes data={ volumes } noDataClass="padding-10" noDataMsg="Nenhum volume informado na NF-e.">
          <ColumnDetalhes field="quantidade" width="10%" className="quebra-de-linha">Qtd.</ColumnDetalhes>
          <ColumnDetalhes field="especie" width="15%" className="quebra-de-linha">Espécie</ColumnDetalhes>
          <ColumnDetalhes field="marca" width="19%" className="quebra-de-linha">Marca</ColumnDetalhes>
          <ColumnDetalhes field="numeracao" width="18%" className="quebra-de-linha">Numeração</ColumnDetalhes>
          <ColumnDetalhes field="pesoLiq" width="19%" className="quebra-de-linha" align="right" classNameTitle="text-right">
            Peso Líq. (Kg)
          </ColumnDetalhes>
          <ColumnDetalhes field="pesoBruto" width="19%" className="quebra-de-linha" align="right" classNameTitle="text-right">
            Peso Bruto (Kg)
          </ColumnDetalhes>
        </TableDetalhes>

        { lacres && lacres.length > 0 &&
        <>
          <h3>Lacres</h3>
          <TableDetalhes data={ lacres } noDataClass="padding-10" noDataMsg="Nenhum lacre informado na NF-e.">
            <ColumnDetalhes field="numero">Número dos Lacres</ColumnDetalhes>
          </TableDetalhes>
        </>
        }
      </div>
  );
}
