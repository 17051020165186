// @flow
'use strict';

import * as React from 'react';

import type { IModal } from '../../types';

import Rodoviario from './rodoviario';
import Aereo from './aereo';
import Ferroviario from './ferroviario';
import Aquaviario from './aquaviario';
import Dutoviario from './dutoviario';
import Multimodal from './multimodal';
import RodoviarioOs from './rodoviario_os';

type ModalProps = {
  modal? : IModal,
};

export default function Modal({ modal } : ModalProps) {
  if (!modal)
    return <h4 className="text-center" style={ { fontStyle: 'italic' } }>Nenhum modal encontrado</h4>

  return (
      <div id="table-modal">
        { modal.rodoviario && <Rodoviario rodoviario={ modal.rodoviario }/> }
        { modal.aereo && <Aereo aereo={ modal.aereo }/> }
        { modal.ferroviario && <Ferroviario ferroviario={ modal.ferroviario }/> }
        { modal.aquaviario && <Aquaviario aquaviario={ modal.aquaviario }/> }
        { modal.dutoviario && <Dutoviario dutoviario={ modal.dutoviario }/> }
        { modal.multimodal && <Multimodal multimodal={ modal.multimodal }/> }
        { modal.rodoviarioOs && <RodoviarioOs rodoviarioOs={ modal.rodoviarioOs }/> }
      </div>
  );
}
