// @flow
'use strict';

import * as React from 'react';

import type { IDeclaracaoImportacao } from '../../../types';

import ColumnDetalhes from '../../../../../common/detalhes_documento/column_detalhes';
import TableDetalhes from '../../../../../common/detalhes_documento/table_detalhes';

type DeclaracaoImportacaoProps = {
  declaracao : IDeclaracaoImportacao[],
};

export default function DeclaracaoImportacao({ declaracao } : DeclaracaoImportacaoProps) {
  return (
      <div id="table-declaracao-importacao">
        <h3>Declaração de Importação</h3>
        <TableDetalhes data={ declaracao }>
          <ColumnDetalhes field="numeroDi" width="10%">Número DI</ColumnDetalhes>
          <ColumnDetalhes field="dataRegistro" type="data" width="12%">Data Registro</ColumnDetalhes>
          <ColumnDetalhes field="localDesembaraco" width="17%">Local Desemb.</ColumnDetalhes>
          <ColumnDetalhes field="dataDesembaraco" type="data" width="12%">Data Desemb.</ColumnDetalhes>
          <ColumnDetalhes field="viaTransporte" width="17%">Via Transporte</ColumnDetalhes>
          <ColumnDetalhes field="codExportador" width="32%" className="quebra-de-linha">Cód. Exportador</ColumnDetalhes>
        </TableDetalhes>
      </div>
  );
}
