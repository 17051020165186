// @flow
'use strict';

import * as React from 'react';

import type { ICarga } from '../../types';

import CargaCte from './carga_cte';
import CargaCteos from './carga_cteos';

type CargaProps = {
  carga? : ICarga,
  modeloId? : number,
};

export default function Carga({ carga, modeloId } : CargaProps) {
  if (modeloId === 57)
    return <CargaCte carga={ carga }/>

  return <CargaCteos carga={ carga }/>
}
