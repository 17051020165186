// @flow
'use strict';

import * as React from 'react';

import type { ICarga } from '../../types';

import RowDetalhes from '../../../../common/detalhes_documento/row_detalhes';
import ColumnDetalhes from '../../../../common/detalhes_documento/column_detalhes';
import TableDetalhes from '../../../../common/detalhes_documento/table_detalhes';

type CargaCteosProps = {
  carga? : ICarga,
};

export default function CargaCteos({ carga } : CargaCteosProps) {
  return (
    <div className="tab-pane active">
      <h3>Carga CT-e OS</h3>
      <RowDetalhes data={ carga }>
        <ColumnDetalhes field="descricaoServico">Descrição Serviço</ColumnDetalhes>
      </RowDetalhes>

      { carga && carga.qtdDeCargaCteos &&
      <>
        <h4>Quantidades de Carga</h4>
        <RowDetalhes data={ carga }>
          <ColumnDetalhes field="qtdDeCargaCteos">Quantidade</ColumnDetalhes>
        </RowDetalhes>
      </>
      }

      <h3>Informações dos Documentos Referenciados</h3>
      { carga &&
      <TableDetalhes data={ carga && carga.docsReferenciados } noDataClass="padding-10"
                     noDataMsg="Nenhum documento referenciado informado no CT-e." showFields>
        <ColumnDetalhes field="numero">Número</ColumnDetalhes>
        <ColumnDetalhes field="serie">Série</ColumnDetalhes>
        <ColumnDetalhes field="emissao" type="data">Emissão</ColumnDetalhes>
        <ColumnDetalhes field="valorTransportado" type="moeda">Valor Transportado</ColumnDetalhes>
      </TableDetalhes>
      }

      <h3>Informações de Seguro da Carga</h3>
      { carga &&
      <TableDetalhes data={ carga.infSeguroCarga } noDataClass="padding-10"
                     noDataMsg="Nenhuma informação de seguro da carga no CT-e." showFields>
        <ColumnDetalhes field="responsavelSeguro">Responsável Seguro</ColumnDetalhes>
        <ColumnDetalhes field="seguradora">Seguradora</ColumnDetalhes>
        <ColumnDetalhes field="numApolice">Número Apólice</ColumnDetalhes>
      </TableDetalhes>
      }
    </div>
  );
}
