'use strict';
// @flow

import * as Immutable from 'immutable';
import { createSelector } from 'reselect';

import type { Action, State, Dispatch, GetState } from 'lib/types';
import * as alerts from '../alerts';
import request from '../../lib/request';
import { queryExportacaoSelector } from './lista';
import { formToQuery } from 'lib/viewUtils';

export const ALTERA_MANIFESTACAO     = 'nfe/modal_manifestacao_lote/ALTERA_MANIFESTACAO',
             ALTERA_TOTAL_DOCUMENTOS = 'nfe/modal_manifestacao_lote/ALTERA_TOTAL_DOCUMENTOS',
             ALTERA_QUERY            = 'nfe/modal_manifestacao_lote/ALTERA_QUERY',
             ALTERA_ABA              = 'nfe/modal_manifestacao_lote/ALTERA_ABA',
             EXIBE_ALERTA            = 'nfe/modal_manifestacao_lote/EXIBE_ALERTA',
             FECHA_ALERTA            = 'nfe/modal_manifestacao_lote/FECHA_ALERTA',
             ABRE_MODAL              = 'nfe/modal_manifestacao_lote/ABRE_MODAL',
             FECHA_MODAL             = 'nfe/modal_manifestacao_lote/FECHA_MODAL';

const INITIAL_STATE = Immutable.fromJS({
  manifestacao: null,
  open: false,
  totalDocumentos: 0,
  alertas: '',
  q: '',
  d: '',
});

export default function reducer(state : any = INITIAL_STATE, action : Action = {}) {
  switch (action.type) {
    case ALTERA_MANIFESTACAO:
      return state.set('manifestacao', action.manifestacao);
    case ALTERA_TOTAL_DOCUMENTOS:
      return state.set('totalDocumentos', action.totalDocumentos);
    case ALTERA_QUERY:
      return state.set('q', action.q);
    case ALTERA_ABA:
      return state.set('d', action.d);
    case EXIBE_ALERTA:
      return state.set('alertas', action.alertas);
    case FECHA_ALERTA:
      return state.set('alertas', '');
    case ABRE_MODAL:
      return state.set('open', true);
    case FECHA_MODAL:
      return state.set('open', false);

    default:
      return state;
  }
}

// selectors

export const rootSelector = (state : State) => state.getIn(['nfe', 'modalManifestacaoLote'], Immutable.Map());
export const openSelector = createSelector<*, *, *, *>(rootSelector, (root) => root.get('open', false));
export const manifestacaoSelector = createSelector<*, *, *, *>(rootSelector, (root) => root.get('manifestacao'));
export const totalDocumentosSelector = createSelector<*, *, *, *>(rootSelector, (root) => root.get('totalDocumentos'));
export const alertasSelector = createSelector<*, *, *, *>(rootSelector, (root) => root.get('alertas'));

// actions

function abrirModal() {
  return { type: ABRE_MODAL };
}

function fecharModal() {
  return { type: FECHA_MODAL };
}

// thunk actions

export function abreModal(manifestacao : string, d : string, q : string) {
  return async function(dispatch : Dispatch<*>) {
    await dispatch({ type: ALTERA_MANIFESTACAO, manifestacao });
    await dispatch({ type: ALTERA_QUERY, q });
    await dispatch({ type: ALTERA_ABA, d });
    await dispatch(alteraTotalDocumentos());
    dispatch(abrirModal());
  };
}

export function fechaModal() {
  return function(dispatch : Dispatch<*>) {
    dispatch({ type: ALTERA_MANIFESTACAO, manifestacao: null });
    dispatch({ type: ALTERA_TOTAL_DOCUMENTOS, totalDocumentos: 0 });
    dispatch(fecharModal());
    dispatch(fechaAlertas());
  };
}

export function exibeAlertas(alertas : string) {
  return function(dispatch : Dispatch<*>) {
    dispatch({ type: EXIBE_ALERTA, alertas });
  };
}

export function fechaAlertas() {
  return function(dispatch : Dispatch<*>) {
    dispatch({ type: FECHA_ALERTA });
  };
}

function alteraTotalDocumentos() {
  return async function(dispatch : Dispatch<*>, getState : GetState) {
    await request.get('/app/nfe/total')
        .accept('json')
        .query(queryExportacaoSelector(getState()))
        .then(r => dispatch({ type: ALTERA_TOTAL_DOCUMENTOS, totalDocumentos: r.body.totalDocumentos }))
        .catch(e => {
          dispatch(alerts.ajaxError('Erro ao verificar o total de documentos para download NF-e', e));
        });
  };
}

export function manifestaLoteNfe(form : Immutable.Map, dispatch : Dispatch<*>) {
  form = form.set('manifestacaoLote', {
    'manifestacao': form.get('manifestacao'),
    'justificativa': form.get('justificativa'),
  });

  request.post(`/app/nfe/manifestar_lote/${ form.get('manifestacao') }`)
      .accept('json')
      .query(formToQuery(form.deleteAll(['totalDocumentos', 'open', 'justificativa']).toJS()))
      .then(() => {
        dispatch(fechaModal());
        dispatch(alerts.alert('info', 'Estamos enviando a manifestação dos documentos. À medida que forem enviados à SEFAZ, as situações serão atualizadas.'));
      })
      .catch(e => {
        if (e && e.response && e.response.body && e.response.body.alertas)
          dispatch(exibeAlertas(e.response.body.alertas.join('\n\n')))
      });
}
