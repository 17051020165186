'use strict';
// @flow

import * as Immutable from 'immutable';
import { push } from 'react-router-redux';
import { createSelector } from 'reselect';

import type { Action, Dispatch, GetState, State } from '../../lib/types';
import request from '../../lib/request';
import { ajaxError } from '../alerts';
import { initialize } from '../documento';

export const DETALHES_CARREGADO  = 'nfe/detalhes/DETALHES_CARREGADO';

const INITIAL_STATE = Immutable.fromJS({
  item: {},
});

export default function reducer(state : any = INITIAL_STATE, action : Action = {}) {
  switch (action.type) {
    case DETALHES_CARREGADO:
      return state.set('item', action.body);
    default:
      return state;
  }
}

// selectors

export const rootSelector = (state : State) => state.getIn(['nfe', 'detalhes'], Immutable.Map());
export const itemSelector = createSelector<*, *, *, *>(rootSelector, (root) => root.get('item'));

// actions

export function detalhesCarregado(item : any) {
  return { type: DETALHES_CARREGADO, body: Immutable.fromJS(item) };
}

// thunk actions

export function carregaDetalhes(id : string) {
  return async function(dispatch : Dispatch<any>, getState : GetState) {
    let ok = true;

    await request.get(`/app/nfe/${ id }`)
        .accept('json')
        .then(r => {
          dispatch(push(`/app/nfe/${ id }`));

          dispatch(detalhesCarregado(r.body))
        })
        .catch(e => {
          ok = false;
          if (e && e.response && e.response.body && e.response.body.error && e.response.body.error.message)
            dispatch(ajaxError(e.response.body.error.message, e));

          if (window.location.pathname.split('/')[3] !== 'lista')
            dispatch(push('/app/dashboard'));
        });

    if (ok) {
      const item = itemSelector(getState()).toJS();
      await dispatch(initialize(item.url));
    }
  };
}
