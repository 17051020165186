// @flow
'use strict';

import * as React from 'react';

import type { ICarga } from '../../types';

import DocFiscais from './doc_fiscais';
import DocFiscaisVazio from './doc_fiscais_vazio';
import RowDetalhes from '../../../../common/detalhes_documento/row_detalhes';
import ColumnDetalhes from '../../../../common/detalhes_documento/column_detalhes';
import TableDetalhes from '../../../../common/detalhes_documento/table_detalhes';

type CargaCteProps = {
  carga? : ICarga,
};

export default function CargaCte({ carga } : CargaCteProps) {
  return (
    <div className="tab-pane active">
      <h3>Carga</h3>
      <RowDetalhes data={ carga }>
        <ColumnDetalhes field="valorTotal" type="moeda" width="33%">Valor Total da Carga</ColumnDetalhes>
        <ColumnDetalhes field="produtoPredominante" width="33%">Produto Predominante</ColumnDetalhes>
        <ColumnDetalhes field="outrasCaracteristicas" width="33%">Outras Características</ColumnDetalhes>
      </RowDetalhes>

      <h4>Quantidades de Carga</h4>
      { carga &&
        <TableDetalhes data={ carga.qtdDeCarga } noDataClass="padding-10">
          <ColumnDetalhes field="unidade" width="33%">Unidade</ColumnDetalhes>
          <ColumnDetalhes field="medida" width="33%">Medida</ColumnDetalhes>
          <ColumnDetalhes field="quantidade" width="33%">Quantidade</ColumnDetalhes>
        </TableDetalhes>
      }

      <h3>Documentos Fiscais da Carga</h3>
      { carga && carga.docFiscais ? <DocFiscais docFiscais={ carga.docFiscais }/> : <DocFiscaisVazio/> }
    </div>
  );
}
