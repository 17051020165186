// @flow
'use strict';

import * as React from 'react';

import type { ITributosFederais } from '../../../types_imposto';

import ColumnDetalhes from '../../../../../common/detalhes_documento/column_detalhes';
import RowDetalhes from '../../../../../common/detalhes_documento/row_detalhes';

type TributosFederaisProps = {
  tributosFederais : ITributosFederais,
};

export default function TributosFederais({ tributosFederais } : TributosFederaisProps) {
  return (
    <>
      <h4>Tributos Federais</h4>
      <RowDetalhes data={ tributosFederais } className="no-margin-bottom">
        <ColumnDetalhes field="valorPis" type="moeda" width="50%">Valor do PIS </ColumnDetalhes>
        <ColumnDetalhes field="valorCofins" type="moeda" width="50%">Valor COFINS</ColumnDetalhes>
      </RowDetalhes>
      <RowDetalhes data={ tributosFederais } className="no-margin-bottom">
        <ColumnDetalhes field="valorIr" type="moeda" width="50%">Valor Imposto de Renda</ColumnDetalhes>
        <ColumnDetalhes field="valorInss" type="moeda" width="50%">Valor do INSS</ColumnDetalhes>
      </RowDetalhes>
      <RowDetalhes data={ tributosFederais }>
        <ColumnDetalhes field="valorCsll" type="moeda">Valor do CSLL</ColumnDetalhes>
      </RowDetalhes>
    </>
  );
}
