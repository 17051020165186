// @flow
'use strict';

import * as React from 'react';

import type { IGrupoExportacao } from '../../../types';

import ColumnDetalhes from '../../../../../common/detalhes_documento/column_detalhes';
import TableDetalhes from '../../../../../common/detalhes_documento/table_detalhes';

type GrupoExportacaoProps = {
  grupoExportacao : IGrupoExportacao[],
};

export default function GrupoExportacao({ grupoExportacao } : GrupoExportacaoProps) {
  let exportacoesIndiretas;

  if (grupoExportacao && grupoExportacao.length > 0)
    exportacoesIndiretas = grupoExportacao.filter((grupo) => grupo.exportacaoIndireta).map((grupo) => grupo.exportacaoIndireta);

  return (
      <div id="table-grupo-exportacao">
        <h3>Grupo de Exportação</h3>
        <TableDetalhes data={ grupoExportacao }>
          <ColumnDetalhes field="numAtoConcesDrawback">Ato Conces. Drawback</ColumnDetalhes>
        </TableDetalhes>

        { exportacoesIndiretas && exportacoesIndiretas.length > 0 &&
            <>
              <h4>Exportação Indireta</h4>
              <TableDetalhes data={ exportacoesIndiretas }>
                <ColumnDetalhes field="registroExp" width="20%">Registro Exp.</ColumnDetalhes>
                <ColumnDetalhes field="chaveNfeExp" width="40%">Chave NF-e Exp.</ColumnDetalhes>
                <ColumnDetalhes field="qtdItemExp" width="20%">Qtd. Item Exp.</ColumnDetalhes>
              </TableDetalhes>
            </>
        }
      </div>
  );
}
