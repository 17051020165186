// @flow
'use strict';

import * as React from 'react';

import type { IDadosProprietario } from '../../../types';
import { tipoDocumento } from '../../abas_informacoes';
import { tafOuRegistroEstadual } from './index';

import RowDetalhes from '../../../../../common/detalhes_documento/row_detalhes';
import ColumnDetalhes from '../../../../../common/detalhes_documento/column_detalhes';

type DadosProprietarioProps = {
  dadosProprietario : IDadosProprietario,
};

export default function DadosProprietario({ dadosProprietario } : DadosProprietarioProps) {
  return (
    <>
      <h4>Dados do Proprietário do Veículo</h4>
      <RowDetalhes data={ dadosProprietario } className="no-margin-bottom">
        <ColumnDetalhes field="cnpj">{ tipoDocumento(dadosProprietario) }</ColumnDetalhes>
        { tafOuRegistroEstadual(dadosProprietario) }
        <ColumnDetalhes field="nome">Proprietário</ColumnDetalhes>
      </RowDetalhes>
      <RowDetalhes data={ dadosProprietario }>
        <ColumnDetalhes field="ie">Inscrição Estadual</ColumnDetalhes>
        <ColumnDetalhes field="uf">UF</ColumnDetalhes>
        <ColumnDetalhes field="tipoProprietario">Tipo Proprietário</ColumnDetalhes>
      </RowDetalhes>
    </>
  );
}
