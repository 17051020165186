// @flow
'use strict';

import * as React from 'react';

import type { IDadosProprietario, IRodoviarioOs } from '../../../types';

import RowDetalhes from '../../../../../common/detalhes_documento/row_detalhes';
import ColumnDetalhes from '../../../../../common/detalhes_documento/column_detalhes';
import DadosVeiculo from './dados_veiculo';
import DadosFretamento from './dados_fretamento';

type RodoviarioOsProps = {
  rodoviarioOs : IRodoviarioOs,
};

export default function RodoviarioOs({ rodoviarioOs } : RodoviarioOsProps) {
  return (
    <>
      <h3>Rodoviário OS</h3>
      <RowDetalhes data={ rodoviarioOs }>{ tafOuRegistroEstadual(rodoviarioOs) }</RowDetalhes>

      { rodoviarioOs && <DadosVeiculo dadosVeiculo={ rodoviarioOs.dadosVeiculo }/> }
      { rodoviarioOs && <DadosFretamento dadosFretamento={ rodoviarioOs.dadosFretamento }/> }
    </>
  );
}

export function tafOuRegistroEstadual(data : IRodoviarioOs | IDadosProprietario) {
  if (!data || !data.registroEstadual)
    return <ColumnDetalhes field="taf">Termo Autorização Fretamento (TAF)</ColumnDetalhes>;

  return <ColumnDetalhes field="registroEstadual">Registro Estadual</ColumnDetalhes>;
}
