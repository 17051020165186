// @flow
'use strict';

import * as React from 'react';

import type { IDadosFretamento } from '../../../types';

import RowDetalhes from '../../../../../common/detalhes_documento/row_detalhes';
import ColumnDetalhes from '../../../../../common/detalhes_documento/column_detalhes';

type DadosFretamentoProps = {
  dadosFretamento : IDadosFretamento,
};

export default function DadosFretamento({ dadosFretamento } : DadosFretamentoProps) {
  if (!dadosFretamento)
    return null;

  return (
    <>
      <h3>Dados do Fretamento</h3>
      <RowDetalhes data={ dadosFretamento }>
        <ColumnDetalhes field="tipoFretamento">Tipo Fretamento</ColumnDetalhes>
        <ColumnDetalhes field="dataViagem" type="data">Data Viagem</ColumnDetalhes>
      </RowDetalhes>
    </>
  );
}
