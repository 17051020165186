'use strict';
// @flow

import _ from 'lodash';
import * as React from 'react';
import moment from 'moment';
import * as humps from 'humps';
import * as Immutable from 'immutable';
import { createSelector } from 'reselect';
import createFilterOptions from 'react-select-fast-filter-options';
import { PrefixIndexStrategy, TfIdfSearchIndex } from 'js-search';

import type { ISelectOption } from 'components/common/input/types';

import FormatHelper from './format_helper';

export const formatHelper = new FormatHelper('pt-br');

export function makeRouteHandlers(fn : Function) {
  return {
    onEnter: (newState : any) => fn(newState),
    onChange: (prevState : any, newState : any) => fn(newState),
  };
}

export function formatIfNeeded(value : any, expr : any) {
  if (expr)
    value = _.result(value, expr);

  return _.isDate(value) || /^\d{4}-\d{2}-\d{2}(T\d{2}:\d{2})?/.test(value) ? formatHelper.formatDateTime(value) : value;
}

export function errorToImmutable(erro : Error) {
  return Immutable.fromJS(toJSONError(erro));
}

export function toJSONError(erro : { message : string }) {
  let r;
  if ((r = _.get(erro, 'response.body.error') || _.get(erro, 'response.body')))
    erro = r;
  else if (erro instanceof Error)
    erro = { message: erro.message };

  return erro;
}

type PrepareContentParams = {
  value? : any,
  defaultContent? : any,
  hideEmpty? : bool,
  expr : any,
};

export function prepareContent({ value, defaultContent, hideEmpty, expr } : PrepareContentParams) {
  let content;
  if (value && value.text) {
    content = formatIfNeeded(value.text, expr);
    if (!content && hideEmpty)
      return null;
    if (value.url)
      content = <a href={ value.url }>{ content || defaultContent }</a>;
  }
  else
    content = formatIfNeeded(value, expr);

  if (!content && hideEmpty)
    return null;

  return content || defaultContent;
}

/**
 * Converte um valor para o formato string a ser utilizado em uma querystring.
 */
export function paramToStr(v : number | string | Date | moment | bool | { value : any }) {
  let d;

  if (v && v instanceof Date)
    v = moment(v);
  else if (typeof v === 'string') {
    if ((d = moment(v, ['D/M/YYYY', 'DD/MM/YYYY', 'YYYY-M-D', 'YYYY-MM-DD'], true)).isValid())
      v = d;
  }

  if (v && v instanceof moment)
    return v.isValid() ? v.format('YYYY-MM-DD') : undefined;

  if (v && typeof v === 'object' && !_.isArray(v)) {
    // eslint-disable-next-line no-prototype-builtins
    if (v.hasOwnProperty('value'))
      return paramToStr(_.result(v, 'value'));
    else
      throw new Error(`Parâmetro não pôde ser convertido: ${ JSON.stringify(v) }`);
  }

  return (v || v === 0 || v === false) && String(v) || undefined;
}

/**
 * Prepara um objeto para ser utilizado como querystring:
 *
 * 1. converte todos os valores para string (inclusive datas);
 * 2. remove os valores nulos, indefinidos e padrão
 */
export function prepareQuery(q : {}, defaultsToRemove : {} = { page: '1' }) {
  return _(q)
      .mapValues(paramToStr)
      .omitBy((v, k) => v === undefined || v === null || v === '' || defaultsToRemove[k] === v)
      .value();
}

export function formataMunicipio({ nome, uf } : { +nome? : string, +uf? : string } = {}) : string {
  return [nome, uf].filter(v => v).join(' / ');
}

export function optionLabel(options : ISelectOption[], value : ?string) : ?string {
  const op = options.find(op => op.value === value);
  return op && op.label;
}

/**
 * Cria um novo selector com base no seletor informado. O novo selector irá chamar o método 'toJS()'
 * do resultado do selector anterior. É útil na montagem de componentes container (conectados à store),
 * pois evita que os componentes tenham que lidar com props immutable.
 *
 * @param selector o selector de origem
 * @param whenFalsy o valor a ser retornado se o resultado for nulo
 *
 * @deprecated
 */
export function jsSelector<R : Immutable.Map>(selector : (any) => R, whenFalsy : any = {}) {
  // $FlowIgnore
  return createSelector(selector, r => (r ? r.toJS() : whenFalsy));
}

export function dynRoute(importPromise : () => Promise<{ default : any }>) {
  return async function(location : any, cb : Function) {
    try {
      const mod = await importPromise();
      cb(null, mod.default);
    }
    catch (e) {
      window.console.error('Erro ao carregar:', e);
      cb(e);
    }
  };
}

const searchIndex   = new TfIdfSearchIndex(),
      indexStrategy = new PrefixIndexStrategy(),
      sanitizer     = {
        sanitize(text : string) {
          return formatHelper.removeDiacritics(text).toLowerCase();
        },
      };

export function makeSearch(options : { value : any }[]) {
  return createFilterOptions({ options, searchIndex, indexStrategy, sanitizer });
}

/**
 * Extrai o 'dataset' de um elemento HTML, de maneira compatível com o método data() do jQuery.
 */
export function htmlDatasetToObject(el : HTMLElement) : { [k : string] : any } {
  return _.mapValues(Object.assign({}, el.dataset), (v : string) => {
    let n;
    if (/^[[{]/.test(v))
      return JSON.parse(v);

    if (String(n = Number(v)) === v)
      return n;

    if (v === 'false')
      return false;

    return v;
  });
}

export function useEffectOnce(fn : () => void | (() => void)) {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  React.useEffect(fn, []);
}

export function decamelizeObjectKeys(obj : any) {
  return Object.getOwnPropertyNames(obj)
      .filter(k => !k.startsWith('__'))
      .reduce((r : any, k : string) => {
        const v = obj[k];
        if (k.charAt(0) !== '_')
          k = humps.decamelize(k);

        r[k] = v;

        return r;
      }, {});
}

export function formToQuery(form : any) {
  return decamelizeObjectKeys(_.pickBy(form, _.identity));
}

export function flattenForm(form : any) {
  if (!_.isObject(form))
    return form;

  let flattedForm = {};

  Object.keys(form).forEach((k) => {
    const value = form[k];
    if (_.isObject(value))
      flattedForm = _.merge(flattedForm, value);
    else
      flattedForm[k] = value;
  });

  return flattedForm;
}
